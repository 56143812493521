import React from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';

const PollarixURL = process.env.REACT_APP_POLLARIX_URL || 'https://pollarix.com';

const Footer = () => {
    return (
       <Box
            dir="ltr" 
            component="footer"
            sx={{
                py: 1,
                position: 'fixed',
                bottom: 0,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                color: 'text.secondary',
                backgroundColor: 'background.paper',
            }}
        >
            <MuiLink
                href={PollarixURL}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: 'inherit', 
                    gap: 0.7, 
                }}
            >
                <Typography variant="body2" fontSize={12}>
                    Powered by
                </Typography>
                <Box
                    component="img"
                    height={14}
                    src={'https://res.cloudinary.com/dqmh1ep5t/image/upload/w_200,f_auto/v1734625884/Pollarix-assets/logo-light.png'}
                    alt="Pollarix Logo"
                />
            </MuiLink>
        </Box>

    );
};

export default Footer;