import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TakeSurvey from "./components/TakeSurvey";
import TakeSurveyCollector from "./components/TakeSurveyCollector";
import { Box } from "@mui/material";
import NotFoundPage from "./components/common/NotFoundPage";
import SubmittingBackdrop from './components/common/SubmittingBackdrop';
import Footer from './components/Footer';

const App: React.FC = () => {
    return (
        <Box>
            <SubmittingBackdrop />
            <Router>
                <Routes>
                    <Route path="/survey/:id" element={<TakeSurvey />} />
                    <Route path="/collector/:id" element={<TakeSurveyCollector />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Router>
            <Box sx={{ mt: 4 }}>
                <Footer />
            </Box>
        </Box>
    );
};

export default App;
